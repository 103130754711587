<template>
  <div>
    <vs-popup class="invoice-popup" :title="`Invoice #${currentInvoice && currentInvoice.id}`" :active.sync="invoicePopupShow">
      <div class="relative" style="max-width: 750px; width: 100%">
        <InvoicePreview v-if="invoicePopupShow" :currentInvoice="currentInvoice" />
      </div>
    </vs-popup>

    <div class="invoices">
      <div style="display: flex; flex-direction: row; justify-content: flex-end" class="mb-2">
        <div class="ml-2">
          <vs-button @click="downloadMultipleInvoices" :disabled="filteredSelectedInvoices.length === 0">
            Download Invoices ({{ filteredSelectedInvoices.length }})
          </vs-button>
        </div>
        <div class="filters-container ml-2">
          <feather-icon icon="CalendarIcon" class="filters-container-icon-date_range" />
          <invoice-date-range-filter :localeData="localeData" @dateRangeChanged="handleDateRangeChange" />
        </div>
        <div class="ml-2">
          <multiselect
            style="z-index: 999"
            v-model="paymentMethod"
            :options="paymentMethods"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :placeholder="'Choose payment method'"
            :selectLabel="''"
            :selectedLabel="''"
            :deselectLabel="''"
            label="name"
            track-by="value"
            open-direction="bottom"
          ></multiselect>
        </div>
        <div class="ml-2">
          <multiselect
            style="z-index: 999"
            v-model="selectedStatus"
            :options="statuses"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :placeholder="'Choose status'"
            :selectLabel="''"
            :selectedLabel="''"
            :deselectLabel="''"
            label="id"
            track-by="value"
            open-direction="bottom"
          ></multiselect>
        </div>
      </div>

      <vs-row vs-type="flex" vs-justify="flex-end">
        <vs-col vs-type="flex" vs-justify="center" vs-align="end" vs-w="3"> </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="end" vs-w="3"> </vs-col>
      </vs-row>
      <vs-card>
        <vs-table
          ref="table"
          multiple
          v-model="selectedInvoices"
          pagination
          :max-items="itemsPerPage"
          :data="normalizedPathadviceInvoices"
          :noDataText="$t('views.home.noDataAvailable')"
          class="invoices-table"
        >
          <template slot="thead">
            <vs-th class="pricing-invoices__table__heading-title" sort-key="company"> {{ $t('pricing.company') }} </vs-th>

            <vs-th class="pricing-invoices__table__heading-title" sort-key="date"> {{ $t('vue.date') }} </vs-th>

            <vs-th class="pricing-invoices__table__heading-title" sort-key="subscription"> {{ $t('pricing.subscription') }} </vs-th>

            <vs-th class="pricing-invoices__table__heading-title invoices-table__heading-title_text-center" sort-key="amount">
              {{ $t('pricing.amount') }}
            </vs-th>

            <vs-th class="pricing-invoices__table__heading-title invoices-table__heading-title" sort-key="method">
              {{ $t('pricing.paymentMethod') }}
            </vs-th>

            <vs-th class="pricing-invoices__table__heading-title invoices-table__heading-title_text-center" sort-key="status"> Status </vs-th>

            <vs-th class="text-center pricing-invoices__table__heading-title invoices-table__heading-title_text-center" sort-key="id">
              {{ $t('vue.action') }}
            </vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr" :key="tr.id" v-for="tr in data">
                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.company }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">{{ formatDate(tr.date) }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.subscription || tr.title }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate text-center">{{ tr.amount.toFixed(2) }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">
                    <vs-chip :color="colorForPaymentMethod(tr.method)">
                      <vs-avatar icon="payment" />
                      {{ tr.method }}
                    </vs-chip>
                  </p>
                </vs-td>
                <vs-td class="invoices-table__statuses-td">
                  <div class="invoices-table__statuses" v-for="status in statuses.filter((x) => x.methods.includes(tr.method))" :key="status.id">
                    <div
                      class="invoices-table__statuses__status"
                      @click="() => onStatusChange(status.value, tr.id, tr.method)"
                      :class="{
                        'invoices-table__statuses__status--open': status.value === 'open',
                        'invoices-table__statuses__status--paid': status.value === 'paid',
                        'invoices-table__statuses__status--unpaid': status.value === 'unpaid',
                        'invoices-table__statuses__status--expired': status.value === 'expired',
                        'invoices-table__statuses__status--failed': status.value === 'failed',
                        'invoices-table__statuses__status--active': status.value === tr.status
                      }"
                    >
                      {{ status.value }}
                    </div>
                  </div>
                </vs-td>

                <vs-td class="whitespace-no-wrap text-center">
                  <div class="flex justify-center" :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
                    <vx-tooltip :text="'Show Invoice'" position="top" class="mr-4">
                      <vs-button type="border" size="medium" color="#3B86F7" icon-pack="feather" icon="icon-info" @click.stop="showInvoice(tr.id)"></vs-button>
                    </vx-tooltip>
                    <vx-tooltip v-if="tr.previewUrl && tr.previewUrl.length > 0" :text="$t('vue.download')" position="top" class="mb-4 mr-4">
                      <vs-button color="primary" type="border" size="medium" icon-pack="feather" icon="icon-download" @click="downloadInvoice(tr)"></vs-button>
                    </vx-tooltip>
                    <vx-tooltip v-else :text="$t('vue.generateInvoice')" position="top" class="mb-4 mr-4">
                      <vs-button
                        v-if="!tr.generatingInvoice"
                        color="warning"
                        type="border"
                        size="medium"
                        icon-pack="feather"
                        icon="icon-codepen"
                        @click="generateInvoice(tr)"
                      ></vs-button>
                      <div v-else class="fill-row-loading">
                        <div :id="`bt-${tr.id}`" class="vs-con-loading__container loading-indicator"></div>
                      </div>
                    </vx-tooltip>

                    <vx-tooltip :text="$t('vue.sendEmail')" position="top" class="mb-4 mr-4">
                      <vs-button
                        class="vs-con-loading__container"
                        color="primary"
                        type="border"
                        size="medium"
                        icon-pack="feather"
                        icon="icon-file-text"
                        :disabled="tr.previewUrl && tr.previewUrl.trim().length === 0"
                        @click="sendInvoiceEmail(tr)"
                      ></vs-button>
                    </vx-tooltip>
                  </div>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vs-card>
    </div>
  </div>
</template>

<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

let plans = null
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'pathadvice-app') {
  const plansModule = require('@/assets/plans/prod_plans.js')
  plans = plansModule.plans
} else {
  const plansModule = require('@/assets/plans/stage_plans.js')
  plans = plansModule.plans
}

import { mapGetters, mapActions } from 'vuex'

import InvoiceDateRangeFilter from '@/views/home/components/InvoiceDateRangeFilter.vue'
import InvoicePreview from '@/components/invoice/InvoicePreview.vue'
import Multiselect from 'vue-multiselect'
export default {
  name: 'Invoices',
  components: {
    InvoicePreview,
    Multiselect,
    InvoiceDateRangeFilter
  },
  data() {
    return {
      localeData: {
        direction: 'ltr',
        format: 'mm/dd/yyyy',
        separator: ' - ',
        daysOfWeek: this.daysOfWeek(),
        daysOfWeekLong: this.daysOfWeekLong(),
        monthNames: this.monthNames(),
        firstDay: 0
      },
      selectedInvoices: [],
      paymentMethod: null,
      paymentMethods: [
        { value: 'all', name: 'All' },
        { value: 'invoice', name: 'Invoice' },
        { value: 'creditcard', name: 'Credit Card' },
        { value: 'paypal', name: 'Paypal' }
      ],
      selectedStatus: null,
      statuses: [
        {
          id: 'All',
          methods: [],
          value: 'all'
        },
        {
          id: 'Open',
          methods: ['invoice', 'creditcard', 'paypal'],
          value: 'open'
        },
        {
          id: 'Paid',
          methods: ['invoice', 'creditcard', 'paypal'],
          value: 'paid'
        },
        {
          id: 'Unpaid',
          methods: ['invoice', 'creditcard', 'paypal'],
          value: 'unpaid'
        },
        {
          id: 'Expired',
          methods: ['creditcard', 'paypal'],
          value: 'expired'
        },
        {
          id: 'Failed',
          methods: ['creditcard', 'paypal'],
          value: 'failed'
        }
      ],
      invoicePopupShow: false,
      currentInvoice: null,
      itemsPerPage: 5
    }
  },
  watch: {
    selectedStatus() {
      this.$refs.table.currentx = 1
    },
    paymentMethod() {
      this.$refs.table.currentx = 1
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      pathadviceInvoices: 'invoice/pathadviceInvoices'
    }),

    filteredSelectedInvoices() {
      return this.selectedInvoices.filter((x) => x.previewUrl && x.previewUrl.length > 0)
    },

    locale() {
      return this.activeUserInfo.language || this.$i18n.locale
    },
    allPlans() {
      if (plans && plans.length) {
        return plans
      }
      return []
    },
    normalizedPathadviceInvoices() {
      if (this.pathadviceInvoices && this.pathadviceInvoices.length && this.allPlans && this.allPlans.length) {
        return this.pathadviceInvoices
          .map((el) => {
            const selectedPlan = this.allPlans.find((item) => item.id === el.selectedPlanId)
            let currency = ''
            let amount = ''
            if (el.method === 'invoice') {
              currency = el.currency && el.currency.text ? el.currency.text : null
              if (!currency && el.amount && Object.keys(el.amount)) {
                currency = Object.keys(el.amount)[0]
              }
              amount = el.amount && el.amount[currency] ? parseFloat(el.amount[currency]) : 0
            } else {
              currency = el.amount.currency
              amount = parseFloat(el.amount.value) || 0
            }

            const normalizedEl = {
              ...el,
              company: el.company,
              status: el.status,
              amount,
              isFirstInvoice: el.isFirstInvoice,
              date: dayjs.unix(el.createdAt.seconds).locale(this.$i18n.locale).format('YYYY-MM-DD'),
              subscription: (selectedPlan && selectedPlan.description) || el.description,
              billingType: selectedPlan && selectedPlan.billing_type
            }
            return normalizedEl
          })
          .filter((el) => el.amount > 0)
          .filter((el) => {
            if (!this.paymentMethod || this.paymentMethod.value === 'all') {
              return true
            } else {
              return el.method === this.paymentMethod.value
            }
          })
          .filter((el) => {
            if (!this.selectedStatus || this.selectedStatus.value === 'all') {
              return true
            } else {
              return el.status === this.selectedStatus.value
            }
          })
          .sort((a, b) => {
            return b.createdAt.seconds - a.createdAt.seconds
          })
      }
      return []
    }
  },
  methods: {
    ...mapActions({
      updateDateRange: 'invoice/updateDateRange',
      updateInvoice: 'invoice/updateInvoice'
    }),
    async downloadMultipleInvoices() {
      if (this.filteredSelectedInvoices.length > 0) {
        await this.$vs.loading()

        const invoices = this.filteredSelectedInvoices.map((x) => {
          return {
            id: x.id,
            link: x.previewUrl,
            pdfname: `${dayjs.unix(x.createdAt.seconds).format('YY_MM')}_${x.id}_${x.company}.pdf`
          }
        })
        const downloadMultipleInvoicesZip = this.$functions.httpsCallable('downloadInvoiceZip')
        const result = await downloadMultipleInvoicesZip({ invoices, companyId: this.activeUserInfo.company, userId: this.activeUserInfo.uid })
        if (result && result.data && result.data.success) {
          this.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.success'),
            text: 'You will be notified once your download is ready !!',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
          })

          this.selectedInvoices = []
        } else {
          this.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.error'),
            text: result.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
        await this.$vs.loading.close()
      }
    },
    colorForPaymentMethod(method) {
      let color = 'primary'
      switch (method) {
        case 'invoice':
          color = 'grey'
          break

        case 'creditcard':
          color = '#FFD700'
          break

        case 'paypal':
          color = '#169BD7'
          break
      }
      return color
    },
    handleDateRangeChange(value) {
      this.$refs.table.currentx = 1
      setTimeout(() => {
        this.updateDateRange({
          endDate: dayjs(value.endDate).utc().toDate(),
          startDate: dayjs(value.startDate).utc().toDate()
        })
      }, 200)
    },
    capitalizeFirstLetter(s) {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
    },
    daysOfWeek() {
      return [
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.short.sun')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.short.mon')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.short.tue')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.short.wed')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.short.thu')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.short.fri')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.short.sat'))
      ]
    },
    daysOfWeekLong() {
      return [
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.long.sun')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.long.mon')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.long.tue')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.long.wed')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.long.thu')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.long.fri')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.long.sat'))
      ]
    },
    monthNames() {
      return [
        this.$i18n.t('system.months.short.jan'),
        this.$i18n.t('system.months.short.feb'),
        this.$i18n.t('system.months.short.mar'),
        this.$i18n.t('system.months.short.apr'),
        this.$i18n.t('system.months.short.may'),
        this.$i18n.t('system.months.short.jun'),
        this.$i18n.t('system.months.short.jul'),
        this.$i18n.t('system.months.short.aug'),
        this.$i18n.t('system.months.short.sep'),
        this.$i18n.t('system.months.short.oct'),
        this.$i18n.t('system.months.short.nov'),
        this.$i18n.t('system.months.short.dec')
      ]
    },
    formatDate(date) {
      return dayjs(date).locale(this.locale).format('MMM DD, YYYY')
    },
    async onStatusChange(status, id, method) {
      if (method !== 'invoice') {
        return
      }
      await this.$vs.loading()
      try {
        const invoiceRef = await this.$db.collection('pathadvice-invoices').doc(id)
        await invoiceRef.set(
          {
            status
          },
          { merge: true }
        )
        await this.$vs.loading.close()
        this.$vs.notify({
          title: this.$i18n.t('vue.success'),
          text: 'Invoice status succesfully changed',
          type: 'success',
          duration: 3000,
          speed: 100
        })
      } catch (err) {
        await this.$vs.loading.close()
        this.$vs.notify({
          title: this.$i18n.t('vue.error'),
          text: err.message,
          type: 'error',
          duration: 3000,
          speed: 100
        })
      }
    },
    showInvoice(id) {
      if (this.normalizedPathadviceInvoices && this.normalizedPathadviceInvoices.length) {
        this.currentInvoice = this.normalizedPathadviceInvoices.find((el) => el.id === id)
        this.invoicePopupShow = true
      }
    },
    downloadInvoice(currentInvoice) {
      const link = document.createElement('a')
      link.href = currentInvoice.previewUrl
      link.click()
    },
    async generateInvoice(currentInvoice) {
      const invoice = this.pathadviceInvoices.find((x) => x.id === currentInvoice.id)
      if (invoice) {
        invoice.generatingInvoice = true
        this.updateInvoice(invoice)
      }

      setTimeout(() => {
        try {
          const options = {
            background: 'primary',
            color: '#fff',
            container: `#bt-${currentInvoice.id}`,
            scale: 0.45
          }
          this.$vs.loading(options)
        } catch (error) {
          /* eslint-disable no-console */
          console.log(error.message)
        }
      }, 100)

      const params = {}
      if (invoice.method === 'invoice') {
        params.id = currentInvoice.id
      } else {
        params.paymentId = currentInvoice.id
        params.companyId = currentInvoice.companyId
      }

      try {
        const axios = require('axios')
        const response = await axios.get(`${this.$functionsURL}/downloadInvoicePDF`, { params })
        if (response && response.data && response.data.link) {
          invoice.previewUrl = response.data.link
        }
      } catch (error) {
        invoice.generatingInvoice = false
        this.updateInvoice(invoice)
      }

      this.$vs.loading.close(`#bt-${currentInvoice.id}  > .con-vs-loading`)
    },
    async sendInvoiceEmail(invoice) {
      this.$vs.loading()
      const sendEmailForInvoice = this.$functions.httpsCallable('sendEmailForInvoice')
      const result = await sendEmailForInvoice({ invoiceId: invoice.id, companyId: invoice.companyId, method: invoice.method })
      if (result && result.data && result.data.sucess) {
        this.$vs.notify({
          title: this.$i18n.t('vue.success'),
          text: 'Invoice email sent succesfully',
          type: 'success',
          duration: 3000,
          speed: 100
        })
      } else {
        this.$vs.notify({
          title: this.$i18n.t('vue.error'),
          text: result.data.message,
          type: 'danger',
          duration: 3000,
          speed: 100
        })
      }
      this.$vs.loading.close()
    }
  }
}
</script>

<style lang="scss">
.vs-con-table .vs-con-tbody {
  border: none;
  background: none;
}

.yearselect {
  width: 75px;
}

.filters {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media (max-width: 991px) {
    flex-direction: column;
  }

  &-container {
    display: flex;
    align-items: center;
    position: relative;

    &-icon {
      &-filter {
        position: absolute !important;
        z-index: 1000;
        top: 8px;
        left: 9px;
      }

      &-date_range {
        position: absolute !important;
        z-index: 1;
        top: 8px;
        left: 10px;
      }
    }
  }

  @media only screen and (max-width: 481px) {
    flex-direction: column;

    &-container {
      margin-bottom: 15px;
      width: 100%;
    }
  }
}

.fill-row-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .loading-indicator {
    width: 38px;
    float: left;
    height: 38px;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.05);
      transform: translate(0, 4px);
    }

    &.activeLoading {
      opacity: 0 !important;
      transform: scale(0.5);
    }
  }
}

.cs-zindex {
  z-index: 999;
}

.vs-select--options {
  z-index: 999;
}

.invoice-popup {
  .vs-popup {
    max-width: 100%;
    width: 780px !important;
  }
}

.invoices-table {
  &__heading-title_text-center {
    .vs-table-text {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__statuses {
    display: flex;
    align-items: center;

    &-td {
      > span {
        display: inline-flex;
      }
    }

    &__status {
      padding: 2.5px;
      border-radius: 8px;
      margin-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 50px;

      &.invoices-table__statuses__status--open {
        border: 1px solid orange;
        transition: 0.3s ease-in-out;

        &.invoices-table__statuses__status--active {
          background: orange;
          color: white;
        }

        &:hover {
          transition: 0.3s ease-in-out;
          background: orange;
          color: white;
        }
      }

      &.invoices-table__statuses__status--paid {
        border: 1px solid green;
        transition: 0.3s ease-in-out;

        &.invoices-table__statuses__status--active {
          background: green;
          color: white;
        }

        &:hover {
          transition: 0.3s ease-in-out;
          background: green;
          color: white;
        }
      }

      &.invoices-table__statuses__status--expired {
        border: 1px solid rgb(128, 0, 119);
        transition: 0.3s ease-in-out;

        &.invoices-table__statuses__status--active {
          background: rgb(128, 0, 119);
          color: white;
        }

        &:hover {
          transition: 0.3s ease-in-out;
          background: rgb(128, 0, 119);
          color: white;
        }
      }

      &.invoices-table__statuses__status--failed {
        border: 1px solid red;
        transition: 0.3s ease-in-out;

        &.invoices-table__statuses__status--active {
          background: red;
          color: white;
        }

        &:hover {
          transition: 0.3s ease-in-out;
          background: red;
          color: white;
        }
      }

      &.invoices-table__statuses__status--unpaid {
        border: 1px solid red;
        transition: 0.3s ease-in-out;

        &.invoices-table__statuses__status--active {
          background: red;
          color: white;
        }

        &:hover {
          transition: 0.3s ease-in-out;
          background: red;
          color: white;
        }
      }
    }
  }
}
</style>
