<template>
  <div>
    <div
      class="container-fluid invoice-container"
      style="
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        padding: 70px;
        max-width: 750px;
        background-color: #fff;
        border: 1px solid #ccc;
        -moz-border-radius: 6px;
        -webkit-border-radius: 6px;
        -o-border-radius: 6px;
        border-radius: 6px;
        box-sizing: border-box;
      "
    >
      <!-- Header -->
      <header style="box-sizing: border-box">
        <div
          class="row align-items-center"
          style="
            display: flex;
            flex-wrap: wrap;
            margin-top: calc(0rem * -1);
            margin-right: calc(1.5rem * -0.5);
            margin-left: calc(1.5rem * -0.5);
            align-items: center !important;
            box-sizing: border-box;
          "
        >
          <div
            class="col-sm-7 text-center text-sm-start mb-3 mb-sm-0"
            style="
              -ms-flex-preferred-size: 0;
              flex-basis: 0;
              -ms-flex-positive: 1;
              flex-grow: 1;
              max-width: 100%;
              position: relative;
              width: 100%;
              min-height: 1px;
              padding-right: 15px;
              padding-left: 15px;
              max-width: 58.333333%;
              margin-bottom: 1rem !important;
              text-align: left !important;
              flex: 0 0 auto;
              margin-top: 0;
              box-sizing: border-box;
            "
          >
            <img
              style="vertical-align: inherit; box-sizing: border-box"
              id="logo"
              src="https://firebasestorage.googleapis.com/v0/b/pathadvice-app.appspot.com/o/LetsConnect_Logo.4d2c677c.svg?alt=media&token=44e1f2f4-1c13-4dea-9b03-c0d4c48cc3f4"
              title="Let's Connect!"
              alt="Let's Connect!"
            />
          </div>
          <div
            class="col-sm-5 text-center text-sm-end"
            style="
              flex: 0 0 auto;
              text-align: right !important;
              -ms-flex-preferred-size: 0;
              flex-basis: 0;
              -ms-flex-positive: 1;
              flex-grow: 1;
              max-width: 100%;
              position: relative;
              width: 100%;
              min-height: 1px;
              padding-right: 15px;
              padding-left: 15px;
              max-width: 41.666667%;
              box-sizing: border-box;
            "
          >
            <p class="mb-0" style="line-height: 1.9; margin-bottom: 0 !important; box-sizing: border-box; display: flex; flex-direction: column">
              <strong>Invoice Number: </strong>{{ currentInvoice.id }}
            </p>
            <p
              v-if="currentInvoice.method === 'invoice'"
              class="mb-0"
              style="line-height: 1.9; margin-bottom: 0 !important; box-sizing: border-box; display: flex; flex-direction: column"
            >
              <strong>VAT Number: </strong> {{ currentInvoice.vatNumber ? currentInvoice.vatNumber : '-' }}
            </p>
            <p v-else class="mb-0" style="line-height: 1.9; margin-bottom: 0 !important; box-sizing: border-box; display: flex; flex-direction: column">
              <strong>VAT Number: </strong>
              {{ currentInvoice.companyInfo && currentInvoice.companyInfo.vatNumber ? currentInvoice.companyInfo.vatNumber : '-' }}
            </p>
            <p class="mb-0" style="line-height: 1.9; margin-bottom: 0 !important; box-sizing: border-box; display: flex; flex-direction: column">
              <strong>Customer ID: </strong> {{ currentInvoice.companyId }}
            </p>
          </div>
        </div>
        <hr
          style="margin: 1rem 0; color: inherit; background-color: currentColor; border: 0; opacity: 0.25; height: 1px; opacity: 0.15; box-sizing: border-box"
        />
      </header>
      <!-- Main Content -->
      <main style="box-sizing: border-box">
        <div
          class="row"
          style="
            display: flex;
            flex-wrap: wrap;
            margin-top: calc(0rem * -1);
            margin-right: calc(1.5rem * -0.5);
            margin-left: calc(1.5rem * -0.5);
            align-items: center !important;
            box-sizing: border-box;
          "
        >
          <div
            class="col-sm-6 text-sm-end order-sm-1"
            style="
              flex-shrink: 0;
              width: 100%;
              max-width: 100%;
              padding-right: calc(1.5rem * 0.5);
              padding-left: calc(1.5rem * 0.5);
              margin-top: 0;
              flex: 0 0 auto;
              width: 50%;
              order: 1 !important;
              text-align: right !important;
              box-sizing: border-box;
              margin-bottom: auto;
            "
          >
            <strong style="font-weight: bolder; box-sizing: border-box">Pay To:</strong>
            <address style="margin-bottom: 1rem; font-style: normal; line-height: inherit; box-sizing: border-box">
              PATHADVICE International GmbH<br />
              Serlesweg 3<br />
              6161 Natters<br />
              AUSTRIA
            </address>
          </div>
          <div
            class="col-sm-6 order-sm-0"
            style="
              max-width: 100%;
              padding-right: calc(1.5rem * 0.5);
              padding-left: calc(1.5rem * 0.5);
              margin-top: 0;
              flex: 0 0 auto;
              width: 50%;
              order: 0 !important;
              box-sizing: border-box;
            "
          >
            <strong style="font-weight: bolder; box-sizing: border-box">Billed To:</strong>

            <address v-if="currentInvoice.method === 'invoice'" style="margin-bottom: 1rem; font-style: normal; line-height: inherit; box-sizing: border-box">
              {{ currentInvoice.company }} <br />
              {{ currentInvoice.title.name }} {{ currentInvoice.academicTitle }} {{ currentInvoice.firstName }} {{ currentInvoice.lastName }} <br />
              {{ currentInvoice.streetAddress }}<br />
              {{ currentInvoice.zipOrPostalCode }} {{ currentInvoice.city }}<br />
              {{ currentInvoice.country.countryName }}
            </address>
            <address v-else style="margin-bottom: 1rem; font-style: normal; line-height: inherit; box-sizing: border-box">
              {{ currentInvoice.companyInfo.name }} <br />
              {{ currentInvoice.companyInfo.createdByName }} <br />
              {{ currentInvoice.companyInfo.street }}<br />
              {{ currentInvoice.companyInfo.zipcode }} {{ currentInvoice.companyInfo.city }}<br />
              {{ currentInvoice.companyInfo.country && currentInvoice.companyInfo.countryName ? currentInvoice.companyInfo.countryName : '' }}
            </address>
          </div>
        </div>
        <div
          class="row"
          style="
            display: flex;
            flex-wrap: wrap;
            margin-top: 1.5rem;
            margin-right: calc(1.5rem * -0.5);
            margin-left: calc(1.5rem * -0.5);
            box-sizing: border-box;
          "
        >
          <div
            class="col-sm-6"
            style="
              max-width: 100%;
              padding-right: calc(1.5rem * 0.5);
              padding-left: calc(1.5rem * 0.5);
              margin-top: 0;
              flex: 0 0 auto;
              width: 50%;
              box-sizing: border-box;
            "
          >
            <strong style="font-weight: bolder; box-sizing: border-box">Payment Method:</strong><br />
            <span style="box-sizing: border-box">{{ currentInvoice.method }}</span> <br />
            <br />
          </div>
          <div
            class="col-sm-6 text-sm-end"
            style="
              max-width: 100%;
              padding-right: calc(1.5rem * 0.5);
              padding-left: calc(1.5rem * 0.5);
              margin-top: 0;
              flex: 0 0 auto;
              width: 50%;
              text-align: right !important;
              box-sizing: border-box;
            "
          >
            <strong style="font-weight: bolder; box-sizing: border-box">Order Date:</strong><br />
            <span style="box-sizing: border-box">
              {{ invoiceCreatedAt }}<br />
              <br />
            </span>
          </div>
        </div>
        <div
          class="row"
          style="
            display: flex;
            flex-wrap: wrap;
            margin-top: 1.5rem;
            margin-right: calc(1.5rem * -0.5);
            margin-left: calc(1.5rem * -0.5);
            box-sizing: border-box;
          "
        >
          <div
            class="col-sm-6"
            style="
              max-width: 100%;
              padding-right: calc(1.5rem * 0.5);
              padding-left: calc(1.5rem * 0.5);
              margin-top: 0;
              flex: 0 0 auto;
              width: 50%;
              box-sizing: border-box;
            "
          ></div>
          <div
            class="col-sm-6 text-sm-end"
            style="
              max-width: 100%;
              padding-right: calc(1.5rem * 0.5);
              padding-left: calc(1.5rem * 0.5);
              margin-top: 0;
              flex: 0 0 auto;
              width: 50%;
              text-align: right !important;
              box-sizing: border-box;
            "
          >
            <strong style="font-weight: bolder; box-sizing: border-box">Service period:</strong><br />
            <span v-if="currentInvoice.source === 'vega-payment'" style="box-sizing: border-box">
              {{ servicePeriodStart }} - {{ servicePeriodEnd }}<br />
              <br />
            </span>
            <span v-else style="box-sizing: border-box">
              {{ invoiceCreatedAt }} - {{ invoiceDueDate }}<br />
              <br />
            </span>
          </div>
        </div>
        <div
          class="card"
          style="
            position: relative;
            display: flex;
            flex-direction: column;
            min-width: 0;
            word-wrap: break-word;
            background-color: #fff;
            background-clip: border-box;
            border: 1px solid rgba(0, 0, 0, 0.125);
            border-radius: 0.25rem;
          "
        >
          <div
            class="card-header"
            style="
              padding: 0.5rem 1rem;
              margin-bottom: 0;
              background-color: rgba(0, 0, 0, 0.03);
              border-bottom: 1px solid rgba(0, 0, 0, 0.125);
              border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
            "
          >
            <span class="fw-600 text-4" style="font-size: 18px !important; font-size: 1.125rem !important; font-weight: 600 !important">Order Summary</span>
            <span
              v-if="currentInvoice.status === 'failed'"
              class="fw-600 text-4"
              style="color: red; font-size: 18px !important; font-size: 1.125rem !important; font-weight: 600 !important"
            >
              Failed
              <template v-if="currentInvoice.details && currentInvoice.details.failureMessage">: {{ currentInvoice.details.failureMessage }}</template>
            </span>
          </div>
          <div class="card-body p-0" style="padding: 0 !important; flex: 1 1 auto">
            <div class="table-responsive" style="overflow-x: auto; -webkit-overflow-scrolling: touch">
              <table
                class="table mb-0"
                style="
                  --bs-table-bg: transparent;
                  --bs-table-accent-bg: transparent;
                  --bs-table-striped-color: #212529;
                  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
                  --bs-table-active-color: #212529;
                  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
                  --bs-table-hover-color: #212529;
                  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
                  width: 100%;
                  margin-bottom: 1rem;
                  color: #212529;
                  vertical-align: top;
                  border-color: #dee2e6;
                  margin-bottom: 0 !important;
                "
              >
                <thead style="border-color: inherit; border-style: solid; border-width: 0; vertical-align: bottom">
                  <tr style="border-color: inherit; border-style: solid; border-width: 0">
                    <td
                      class="col-8"
                      style="
                        border-color: inherit;
                        border-style: solid;
                        border-width: 0;
                        flex: 0 0 auto;
                        width: 66.66666667%;
                        padding: 0.5rem 0.5rem;
                        background-color: transparent;
                        border-bottom-width: 1px;
                        box-shadow: inset 0 0 0 9999px transparent;
                      "
                    >
                      <strong style="font-weight: bolder; color: #535b61">Pricing plan</strong>
                    </td>
                    <td
                      class="col-2 text-center"
                      style="
                        border-color: inherit;
                        border-style: solid;
                        border-width: 0;
                        flex: 0 0 auto;
                        width: 16.66666667%;
                        text-align: center !important;
                        padding: 0.5rem 0.5rem;
                        background-color: transparent;
                        border-bottom-width: 1px;
                        box-shadow: inset 0 0 0 9999px transparent;
                      "
                    >
                      <strong style="font-weight: bolder; color: #535b61">Billing type</strong>
                    </td>
                    <td
                      class="col-2 text-end"
                      style="
                        border-color: inherit;
                        border-style: solid;
                        border-width: 0;
                        flex: 0 0 auto;
                        width: 16.66666667%;
                        text-align: right !important;
                        padding: 0.5rem 0.5rem;
                        background-color: transparent;
                        border-bottom-width: 1px;
                        box-shadow: inset 0 0 0 9999px transparent;
                      "
                    >
                      <strong style="font-weight: bolder; color: #535b61">Amount in €</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="background-color: transparent !important">
                      <span class="text-3" style="color: #535b61; padding: 0.5rem">{{ currentInvoice.subscription }}</span>
                    </td>
                    <td class="text-center" style="background-color: transparent !important; color: #535b61; padding: 0.5rem">
                      {{ currentInvoice.billingType }}
                    </td>
                    <td class="text-end" style="background-color: transparent !important; color: #535b61; text-align: right; padding: 0.5rem">
                      {{ planAmount }} €
                    </td>
                  </tr>
                </tbody>
                <tfoot
                  class="card-footer"
                  style="
                    border-color: inherit;
                    border-style: solid;
                    border-width: 0;
                    padding: 0.5rem 1rem;
                    background-color: rgba(0, 0, 0, 0.03);
                    border-top: 1px solid rgba(0, 0, 0, 0.125);
                    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
                  "
                >
                  <tr>
                    <td
                      colspan="2"
                      class="text-end"
                      style="
                        color: #535b61;
                        padding: 0.5rem 0.5rem;
                        background-color: transparent;
                        border-bottom-width: 1px;
                        box-shadow: inset 0 0 0 9999px transparent;
                        text-align: right !important;
                      "
                    >
                      <strong>Sub Total:</strong>
                    </td>
                    <td
                      class="text-end"
                      style="
                        color: #535b61;
                        padding: 0.5rem 0.5rem;
                        background-color: transparent;
                        border-bottom-width: 1px;
                        box-shadow: inset 0 0 0 9999px transparent;
                        text-align: right !important;
                      "
                    >
                      {{ planAmount }} €
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      class="text-end"
                      style="
                        color: #535b61;
                        padding: 0.5rem 0.5rem;
                        background-color: transparent;
                        border-bottom-width: 1px;
                        box-shadow: inset 0 0 0 9999px transparent;
                        text-align: right !important;
                      "
                    >
                      <strong>VAT {{ vatPercent }} %:</strong>
                    </td>
                    <td
                      class="text-end"
                      style="
                        color: #535b61;
                        padding: 0.5rem 0.5rem;
                        background-color: transparent;
                        border-bottom-width: 1px;
                        box-shadow: inset 0 0 0 9999px transparent;
                        text-align: right !important;
                      "
                    >
                      {{ taxAmount }} €
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      class="text-end border-bottom-0"
                      style="
                        color: #535b61;
                        padding: 0.5rem 0.5rem;
                        background-color: transparent;
                        border-bottom-width: 1px;
                        box-shadow: inset 0 0 0 9999px transparent;
                        text-align: right !important;
                      "
                    >
                      <strong>Total:</strong>
                    </td>
                    <td
                      class="text-end border-bottom-0"
                      style="
                        color: #535b61;
                        padding: 0.5rem 0.5rem;
                        background-color: transparent;
                        border-bottom-width: 1px;
                        box-shadow: inset 0 0 0 9999px transparent;
                        text-align: right !important;
                      "
                    >
                      {{ totalPrice }} €
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <br />
      </main>
      <!-- Footer -->
      <footer style="text-align: left">
        <p class="text-1" style="font-size: 12px !important; font-size: 0.75rem !important; display: flex; flex-direction: column">
          <strong>Payment Details: </strong>
          <span>1. Please transfer your payment to the following bank account: IBAN: AT62 2050 3033 0277 8315 BIC: SPIHAT22XXX </span>
          <!-- <span>2. PLEASE DO NOT PAY. The amount due will be charged via the selected payment method. </span> -->
        </p>
        <p class="text-1" style="font-size: 12px !important; font-size: 0.75rem !important">
          <strong>NOTE :</strong> Services subject to the reverse charge procedure - VAT must be paid by the recipient in accordance with Article 196 of EU
          Directive 2006/112/EC the recipient is liable.
        </p>
        <p class="text-1" style="font-size: 12px !important; font-size: 0.75rem !important">
          <strong>NOTE :</strong> This is computer generated receipt and does not require physical signature.
        </p>
      </footer>
    </div>
  </div>
</template>

<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  props: {
    currentInvoice: {
      type: Object
    }
  },
  computed: {
    planAmount() {
      if (this.currentInvoice && this.currentInvoice.method === 'invoice') {
        return this.currentInvoice.planAmount
      }
      return this.currentInvoice.metadata && this.currentInvoice.metadata.planAmount ? this.currentInvoice.metadata.planAmount : ''
    },

    taxAmount() {
      if (this.currentInvoice && this.currentInvoice.method === 'invoice') {
        return this.currentInvoice.tax ? this.currentInvoice.tax : '00.00'
      }
      return this.currentInvoice.metadata && this.currentInvoice.metadata.taxAmount ? this.currentInvoice.metadata.taxAmount : '0.00'
    },
    vatPercent() {
      if (this.currentInvoice && this.currentInvoice.method === 'invoice') {
        return this.currentInvoice.percent || 0
      }
      return this.currentInvoice.metadata && this.currentInvoice.metadata.vatPercent ? this.currentInvoice.metadata.vatPercent : 0
    },
    invoiceCreatedAt() {
      if (this.currentInvoice && this.currentInvoice.createdAt) {
        return dayjs.unix(this.currentInvoice.createdAt.seconds).utc().format('MM-DD-YYYY')
      }
      return ''
    },
    servicePeriodStart() {
      if (this.currentInvoice && this.currentInvoice.servicePeriodStart) {
        return dayjs(this.currentInvoice.servicePeriodStart).format('MM-DD-YYYY')
      }
      return ''
    },
    servicePeriodEnd() {
      if (this.currentInvoice && this.currentInvoice.servicePeriodEnd) {
        return dayjs(this.currentInvoice.servicePeriodEnd).utc().format('MM-DD-YYYY')
      }
      return ''
    },
    invoiceDueDate() {
      if (this.currentInvoice && this.currentInvoice.method === 'invoice') {
        if (this.currentInvoice && this.currentInvoice.dueDate) {
          return dayjs.unix(this.currentInvoice.dueDate.seconds).utc().format('MM-DD-YYYY')
        }
      }

      if (this.currentInvoice && this.currentInvoice.method !== 'invoice') {
        if (this.currentInvoice && this.currentInvoice.companyInfo && this.currentInvoice.companyInfo.expiryDate) {
          return dayjs.unix(this.currentInvoice.companyInfo.expiryDate.seconds).utc().format('MM-DD-YYYY')
        }
      }

      return ''
    },
    totalPrice() {
      return this.currentInvoice.amount.toFixed(2)
    }
  }
}
</script>

<style lang="scss">
.invoice-container {
  @media (max-width: 575px) {
    padding: 15px !important;
  }
}
</style>
